import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-2/pic1.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-2/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-2/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-2/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-2/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-2/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/4-aug-2/pic7.jpeg",
];

export default function YellowDayCelebrations() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Yellow Day Celebrations</h1>

        <p>
          The 'Yellow Day' was celebrated by the Pre-Primary Department on 31
          July 2023. It aimed at helping the little learners recognise the color
          ' Yellow' . They learnt to relate the colour with the things around
          them. The craft activity of sponge dabbing on sun was done to develop
          the fine motor skills of the learners. The learners were dressed up in
          yellow attires and enjoyed drinking their favourite summer drink
          frooti.
          <br />
          <br />
          It was a wonderful learning experience for them. They were happy and
          enjoyed the activity to the fullest.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
